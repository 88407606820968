body {
  background-color: #131312;
}

p,
h1,
h2,
hr,
footer,
label,
i {
  color: whitesmoke;
}

.experience-table {
  background-color: #131312 !important;
}

table {
  table-layout: auto;
}

td {
  word-break: break-word;
}
